@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger:focus {
  outline: none;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000b3c;
}

.hamburger-box {
  width: 22px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 23px;
  height: 3px;
  position: absolute;
  border-radius: 5px;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  background-color: #000b3c;
}

.hamburger-inner-isDark,
.hamburger-inner-isDark::before,
.hamburger-inner-isDark::after {
  background-color: #01163e;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -6px;
}
.hamburger-inner::after {
  bottom: -6px;
}

/*
     * Spin
     */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.container-timer {
  width: 200px;
  text-align: center;
}

.timer {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  float: none;
  margin: 0 auto;
}

.timer div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cfcfd0;
  border-radius: 50%;
  -webkit-animation: timer 1.2s linear infinite;
          animation: timer 1.2s linear infinite;
}

.timer div:nth-child(2) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.timer div:nth-child(3) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.timer div:nth-child(4) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.timer div:nth-child(5) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.timer div:nth-child(6) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.timer div:nth-child(7) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.timer div:nth-child(8) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.timer div:nth-child(9) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.timer div:nth-child(10) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.timer div:nth-child(11) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.timer div:nth-child(12) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.timer div:nth-child(13) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.timer span {
  position: absolute;
  font-family: 'Teko', sans-serif;
  top: 25px;
  left: 28px;
  font-size: 25px;
  color: #000b3c;
  width: 25px;
  text-align: center;
}

@-webkit-keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes timer {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

p {
  font-size: 12px;
  line-height: 18px;
  font-family: 'CenturyGothicBold', sans-serif;
  color: #000b3c;
}

@font-face {
  font-family: 'CenturyGothic';
  src: url(/static/media/CenturyGothic.09f6b2ba.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBold';
  src: url(/static/media/CenturyGothicBold.7bea0c92.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicItalic';
  src: url(/static/media/CenturyGothicItalic.87a92153.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicBoldItalic';
  src: url(/static/media/CenturyGothicBoldItalic.abd76d61.ttf) format('TrueType');
}
@font-face {
  font-family: 'CenturyGothicLight';
  src: url(/static/media/CenturyGothicLight.8917856b.ttf) format('TrueType');
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format('TrueType');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url(/static/media/Montserrat-Bold.ade91f47.ttf) format('TrueType');
  font-weight: bold;
  font-style: normal;
}

:root {
  --pink900: #de0c4b;
  --pink800: #ff5776;
  --brand900: #002133;
  --brand800: #003756;
  --brand700: #0086cb;
  --brand600: #5cb5fe;
  --brandPrimary: #006eab;
  --brandPrimaryLight: #e6f1f7;
  --brandPrimaryDark: #004466;
  --brandSecundary: #004d78;
  --brandSecundaryLight: #80b7d5;
  --brandSecundaryDark: #00639a;
  --yellow900: #fccc00;
  --yellow800: #ffff50;
  --lilac900: #7a1fa0;
  --lilac800: #ad52d2;
  --purple900: #6600f7;
  --purple800: #a248ff;
  --grey600: #b0afaf;
  --grey500: #cfcfcf;
  --grey400: #ffffff;
  --green100: #89d747;
  --greyPrimary: #575757;
  --greyNeutral: #767676;
  --greyLight: #cfcfcf;
  --red100: #dd4646;
  --red200: #ff5776;
  --red300: #b30142;
  --white100: #ffffff;
  --black100: #000000;
}

* {
  -webkit-overflow-scrolling: inherit;
}

body {
  margin: 0;
  font-family: 'CenturyGothic', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiInputBase-root {
  font-family: 'CenturyGothic', sans-serif !important;
  width: 100% !important;
}

.MuiFormControl-marginNormal {
  width: 100%;
}

.MuiButton-outlined {
  border: 1px solid #de0c4b;
}

.Toastify__toast-body {
  font-family: 'CenturyGothic', sans-serif !important;
}

.Toastify__toast {
  padding: 16px !important;
}

.Toastify__toast--default {
  border-radius: 20px;
}

.custom__tooltip {
  border-radius: 16px !important;
}

[tooltip-text] {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

[tooltip-text]:hover::before {
  display: block;
  content: attr(tooltip-text);
  background: #00198a;
  border-radius: 8px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: -30px;
  line-height: 16px;
  width: 350px;
  padding: 16px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  [tooltip-text]:hover::before {
    left: -96px;
    width: 296px;
  }
}

[tooltip-text]:hover::after {
  display: block;
  content: '';
  font-size: 0;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  position: absolute;
  top: -18px;
  left: -4px;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 0;
  border-top: 17px solid #00198a;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
}

@media only screen and (max-width: 320px) {
  .typeform-popover-wrapper iframe {
    width: 90% !important;
  }
}

